/* eslint-disable no-param-reassign */
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import authService from '../auth-service';

const axiosApiInstance = axios.create({
  timeout: 10000,
  baseURL: process.env.VUE_APP_BASE_ROOT,
});

// Request interceptor for API CALLS
axiosApiInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = await authService.getToken();

    if (config.data === undefined) config.data = null;
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }
    config.headers.charset = 'utf-8';
    if (token) {
      // only add auth if we have a token
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      const token = await authService.getToken();
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  },
);

export default axiosApiInstance;
