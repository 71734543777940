import { UserRole } from '@/service/client/users-client';
//  dumbassy rules from fucking ts lint the fuck is this
export const hasRole = (
  roles : UserRole[],
  role : UserRole,
) : boolean => {
  if (!roles || !role) {
    return false;
  }
  return roles.some((e) => e === role);
};

export const hasAnyRole = (
  currentRoles : UserRole[],
  candidateRoles: UserRole[],
) : boolean => {
  if (!currentRoles || !candidateRoles) {
    return false;
  }
  return candidateRoles
    .some((e) => currentRoles.includes(e));
};
