import { UserRole } from '@/service/client/users-client';
import { createStore } from 'vuex';

export interface StoreUser {
  email: string,
  roles: UserRole[]
  uid: string
}

const initialState : {user:StoreUser} = {
  user: {
    email: '',
    roles: [] as UserRole[],
    uid: '',
  },
};

export default createStore({
  state: { ...initialState },
  getters: {
    // Example getters to access user information
    userEmail: (state) : string => state.user.email,
    userRoles: (state) : UserRole[] => state.user.roles,
    userId: (state) : string => state.user.uid,
  },
  mutations: {
    // Mutation to update user information
    updateUser(state, { email, roles, uid }) : void {
      state.user.email = email;
      state.user.roles = roles;
      state.user.uid = uid;
    },
  },
  actions: {
    // Action to save user information in local storage
    saveUserInfo({ state }) {
      this.commit('updateUser', state.user);
      localStorage.setItem('user', JSON.stringify(state.user));
    },

    // Action to load user information from local storage
    loadUserInfo({ commit }) {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        const { email, roles, uid } = JSON.parse(storedUser);
        commit('updateUser', { email, roles, uid });
      }
    },
  },
  modules: {},
});
