import { AxiosInstance } from 'axios';
import axiosApiInstance from './axios-client';

export interface Version {
    name: string,
    link: string,
    createdDate: string

}
export type Versions = Version[]

class VersionsClient {
  constructor(private axios: AxiosInstance) {
    this.axios = axios;
  }

  private static BASE_URL = '/web/mirrorAppUsers/v1';

  async getAllVersions(userId: string) : Promise<Versions> {
    const response = await this.axios.get(`${VersionsClient.BASE_URL}/users/${userId}/appVersions`, {
      params: {
        operatingSystem: 'ANDROID',
      },
    });
    return response.data;
  }
}

export const versionsClient = new VersionsClient(axiosApiInstance);
