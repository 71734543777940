import {
  Auth, User, getAuth, signInWithEmailAndPassword,
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import firebaseConfig from '@/config/initialize';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export class AuthService {
  constructor(private firebaseAuth: Auth) {
    this.firebaseAuth = firebaseAuth;
  }

  async login(email: string, password: string): Promise<User| null> {
    const data = await signInWithEmailAndPassword(this.firebaseAuth, email, password);
    return data?.user;
  }

  async logout(): Promise<void> {
    await this.firebaseAuth.signOut();
  }

  async getToken(): Promise<string | undefined> {
    const user = this.firebaseAuth.currentUser;
    const token = await user?.getIdToken(true);
    return token;
  }

  getUser() : User | null {
    return this.firebaseAuth.currentUser;
  }
}

export default new AuthService(auth);
