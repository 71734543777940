import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import authService from '@/service/auth-service';
import { useStore } from 'vuex';
import { hasAnyRole } from '@/util/role-util';
import { UserRole } from '@/service/client/users-client';
import store from '../store';
import LoginView from '../views/login/LoginView.vue';
import VersionsView from '../views/versions/VersionsView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: LoginView,
    beforeEnter: async (to, from, next) => {
      await store.dispatch('loadUserInfo');
      const roles = store.getters.userRoles;
      const isAuthenticated = hasAnyRole(roles, [UserRole.CONROO_ADMIN, UserRole.CONROO_READ]);
      if (isAuthenticated) {
        next({ name: 'versions' });
      } else {
        next();
      }
    },
  },
  {
    path: '/versions',
    name: 'versions',
    component: VersionsView,
    beforeEnter: async (to, from, next) => {
      await store.dispatch('loadUserInfo');
      const roles = store.getters.userRoles;
      // TODO: replace with actual roles
      const isHome = to.name === 'home';

      const isAuthenticated = hasAnyRole(
        roles,
        [UserRole.MIRROR_APP_READ, UserRole.MIRROR_APP_WRITE],
      );
      if (!isHome) {
        if (isAuthenticated) {
          next();
        } else {
          next({ name: 'home' });
        }
      } else {
        next();
      }
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
