<template>
    <div class="container mx-auto">
      <div  class="w-full p-4">
        <div>
          <div class="flex justify-between">
            <p class="text-lg font-semibold">Version</p>
            <p class="text-lg font-semibold">Date</p>
            <p class="text-lg font-semibold">Download</p>
        </div>
      </div>
    </div>
    </div>
    </template>
