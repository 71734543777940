import { AxiosInstance } from 'axios';
import axiosApiInstance from './axios-client';

// eslint-disable-next-line no-shadow
export enum UserRole {
    TERMINAL_OPERATIONS_READ = 'terminal_operations_read',
    TERMINAL_OPERATIONS_WRITE = 'terminal_operations_write',
    TERMINAL_OPERATIONS_ADMIN = 'terminal_operations_admin',
    FORWARDER_READ = 'forwarder_read',
    FORWARDER_WRITE = 'forwarder_write',
    TRAIN_OPERATOR_READ = 'train_operator_read',
    TRAIN_OPERATOR_WRITE = 'train_operator_write',
    CONROO_READ = 'conroo_read',
    CONROO_WRITE = 'conroo_write',
    CONROO_ADMIN = 'conroo_admin',
    MIRROR_APP_WRITE = 'mirror_app_write',
    MIRROR_APP_READ = 'mirror_app_read'
  }

interface WebappUser {
    id: string;
    locale: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: UserRole[];
  }

class UsersClient {
  constructor(private axios: AxiosInstance) {
    // TODO: check on changing base url
    this.axios = axios;
  }

  private static BASE_URL = '/web/v1';

  async getUser(userId: string) : Promise<WebappUser> {
    const response = await this.axios.get(`${UsersClient.BASE_URL}/users/${userId}`);
    return response.data;
  }
}

export default new UsersClient(axiosApiInstance);
